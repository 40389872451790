.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dropdown-item-secondary:hover {
    background-color: #e4e5e6;
}

.dropdown-item-primary:hover {
    background-color: #0c6dfd;
    color: white;
}

.text-hover-white:hover {
    color: white !important;
}

.font-size-12 {
    font-size: 12pt;
}
.font-size-11 {
    font-size: 11pt;
}
.font-size-10 {
    font-size: 10pt;
}
.font-size-9 {
    font-size: 9pt;
}
.font-size-8 {
    font-size: 8pt;
}
.font-size-7 {
    font-size: 7pt;
}
.font-size-6 {
    font-size: 6pt;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn-orange {
    color: #ffffff;
    background-color: #f84002;
    border-color: #e73b02;
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active,
.open .dropdown-toggle.btn-orange {
    color: #ffffff;
    background-color: #e83b02;
    border-color: #e43a02;
}

.btn-orange:active,
.btn-orange.active,
.open .dropdown-toggle.btn-orange {
    background-image: none;
}

.btn-orange.disabled,
.btn-orange[disabled],
fieldset[disabled] .btn-orange,
.btn-orange.disabled:hover,
.btn-orange[disabled]:hover,
fieldset[disabled] .btn-orange:hover,
.btn-orange.disabled:focus,
.btn-orange[disabled]:focus,
fieldset[disabled] .btn-orange:focus,
.btn-orange.disabled:active,
.btn-orange[disabled]:active,
fieldset[disabled] .btn-orange:active,
.btn-orange.disabled.active,
.btn-orange[disabled].active,
fieldset[disabled] .btn-orange.active {
    background-color: #f84002;
    border-color: #e73b02;
}

.btn-orange .badge {
    color: #f84002;
    background-color: #ffffff;
}

/* Teal button */

.btn-teal {
    background-color: #36afac; /* Teal background */
    color: #ffffff; /* White text */
    border: none; /* Remove default border */
}

.btn-teal:hover {
    background-color: #2d9593; /* Darker teal for hover effect */
    color: #ffffff;
}

/* .btn-teal:focus, */
.btn-teal:active {
    background-color: #27807d; /* Even darker teal for active/focus states */
    color: #ffffff;
    outline: none; /* Remove focus outline */
    /* box-shadow: 0 0 0 0.2rem rgba(54, 175, 172, 0.5); Teal shadow for focus */
}

.btn-outline-teal {
    color: #36afac; /* Text color teal */
    border: 1px solid #36afac; /* Teal outline */
    background-color: transparent; /* Transparent background */
}

.btn-outline-teal:hover {
    background-color: #36afac; /* Fill background on hover */
    color: #ffffff; /* White text on hover */
}

/* .btn-outline-teal:focus, */
.btn-outline-teal:active {
    background-color: #2d9593; /* Darker teal for active/focus states */
    color: #ffffff;
    outline: none; /* Remove default outline */
    /* box-shadow: 0 0 0 0.2rem rgba(54, 175, 172, 0.5); Teal shadow for focus */
}

.text-orange {
    color: #f84002; /* Orange text color */
}

.nav-link {
    color: black;
    padding: 0.5em 1em;
    height: 46px;
}
.nav-link:hover {
    color: #36afac;
}
.nav-link.active {
    border-top: 2px solid #36afac !important;
    height: 46px;
    color: #36afac !important;
}

.double-col {
    column-count: 2;
    column-gap: 1.5em;
    break-inside: avoid-column;
}

@media (max-width: 576px) {
    .double-col {
        column-count: 1;
    }
}
