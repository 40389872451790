.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(
        255,
        255,
        255,
        0.2
    ); /* Optional: semi-transparent background */
    z-index: 9999;
}

.spinner {
    border: 5px solid #ffe0e0; /* Light color for the background part */
    border-top: 5px solid #db4534; /* Primary color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
