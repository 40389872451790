.SingleDatePicker,
.DateRangePicker {
    width: 100%;
}

.SingleDatePickerInput,
.DateRangePickerInput {
    height: 60px;
    text-align: left;
    display: block;
    width: 100%;
    color: #000;
    appearance: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #000;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.SingleDatePicker_button__verticalDefault,
.DayPickerNavigation_button__verticalDefault {
    height: auto;
}

.DayPickerNavigation_svg__vertical {
    height: 24px;
}

.DayPickerNavigation__verticalDefault {
    height: auto;
}

.DateInput {
    width: 50%;
}

.SingleDatePickerInput .DateInput {
    width: 100%;
}

.DateInput_input {
    font-size: 12pt;
    font-weight: normal;
    padding: 0.5em 0.5em 0.5em 0.9em;
    width: 100%;
    color: #f84002;
    border: none;
    text-align: start;
}

.DateInput_input:not(:placeholder-shown) {
    padding-top: 1em;
    padding-bottom: 0em;
}

.DateInput_input__focused {
    border: none;
}

.DateInput #start-date {
    border-right: 1px solid #d1d1d1;
}
.DateInput #start-date:not(:placeholder-shown) {
    padding-top: 1em;
    padding-bottom: 0em;
}

.DateInput #end-date:not(:placeholder-shown) {
    padding-top: 1em;
    padding-bottom: 0em;
}

.DateInput .DateRangePickerInput_arrow {
    margin-left: 0.1em;
    margin-right: 0.1em;
}

.DateInput_fang {
    top: 50px !important;
}

.DateRangePickerInput_arrow_svg {
    /* width: 1.25rem; */
    display: none;
}

.DayPickerKeyboardShortcuts_buttonReset {
    display: none;
}

/* .SingleDatePickerInput_clearDate,
.DateRangePickerInput_clearDates {
    margin-left: 0.5em;
} */

.SingleDatePickerInput_clearDate:hover,
.DateRangePickerInput_clearDates:hover {
    border-radius: 4px;
    background-color: #f6f6f6;
}

.SingleDatePickerInput_clearDate_svg,
.DateRangePickerInput_clearDates_svg {
    fill: #000;
    width: 8px;
}

.DayPickerNavigation_button {
    border: 0.5px solid #ddd;
    box-shadow: none;
}

.DayPickerNavigation_svg__horizontal {
    fill: #000;
}

.DayPickerNavigation_button:hover {
    border: 1px solid #000;
}

.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active,
.CalendarDay__selected_start,
.CalendarDay__selected_start:hover {
    background-color: #085ed6;
    background: #085ed6;
    border: 1px solid #085ed6;
}

.CalendarDay__selected_end,
.CalendarDay__selected_end:hover {
    background-color: #085ed6;
    border: 1px solid #085ed6;
}

.CalendarDay__selected_span {
    background-color: #a5c6f4;
    border: 1px solid #ddd;
}

.CalendarDay__selected_span:hover {
    background-color: #6a98d9;
    border: 1px solid #ddd;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background-color: #d3e6ff;
    border: 1px solid #fff;
    color: #000;
}

/* General date picker styling */
.responsive-date-range-picker {
    max-width: 100%;
    margin: 0 auto;
}

/* Adjust padding and margins on mobile */
@media (max-width: 768px) {
    .responsive-date-range-picker .DateRangePicker {
        width: 100%;
    }

    .responsive-date-range-picker .DateInput_input {
        font-size: 16px;
        padding: 10px;
    }

    .responsive-date-range-picker .DateRangePicker_picker {
        top: 50px !important; /* Adjust as needed for alignment */
    }
}
